<template>
  <!-- 友情链接 -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
            <el-form ref="form" inline :model="searchData" label-width="">
              <el-row :gutter="20">
                <el-form-item label="名称">
                  <el-input style="width:250px" v-model="searchData.name" size="small" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
                    <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
                </el-form-item>
                </el-row>
            </el-form>
        </div>
    <el-button style="margin-bottom: 20px" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'FriendlinkAdd' })">
      添加
    </el-button>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getListAPI, delAPI, changeStatusAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

const columns = [
  {
    label: '名称',
    prop: 'name',
    minWidth: '120',
  },
  {
    label: '地址',
    prop: 'url',
    minWidth: '120',
  },
  {
    label: '描述',
    prop: 'description',
    minWidth: '120',
  },
  // {
  //   label: '状态',
  //   prop: 'status',
  //   minWidth: '110',
  //   customRender(h, row) {
  //     return (
  //       <p class="one-line" title={row['status']}>
  //         <el-switch
  //           class="switch"
  //           onChange={() => this.changeStatus(row)}
  //           v-model={row['status']}
  //           active-value={20}
  //           inactive-value={10}
  //           active-text="上架"
  //           inactive-text="下架"
  //         ></el-switch>
  //       </p>
  //     )
  //   }
  // },
  {
    label: '排序',
    minWidth: '100',
    prop: 'sort',
    customRender(h, row) {
      return <p>{row['sort']}</p>
    }
  },
  {
    label: '操作',
    fixed: 'right',
    width: '150',
    customRender(h, row) {
      return (
        <div>
          <el-link
            type="primary"
            style="margin-right:10px;"
            onClick={() => this.$router.push({ name: 'FriendlinkEdit', params: { id: row['id'] || undefined } })}
          >
            编辑
          </el-link>
          <el-link type="danger" onClick={() => this.goRemove(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      list: [],
      page_size: 10,
      searchData: {
        name: ''
      },
      total: 0,
      currentPage: 1,
      columns
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      let params = {
        page: this.currentPage,
        page_size: this.page_size
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    async goRemove(id) {
      this.$confirm('此操作将永久删除该banner, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 20 ? 20 : 10 }
      let statusDesc = status == 10 ? '禁用' : '启用'

      this.$confirm(`此操作将该banner${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          row.status = status == 20 ? 20 : 10
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 10 ? 20 : 10
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
