import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/manage/friendLink/list`,
    method: 'post',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/manage/friendLink/del?id=${id}`,
    method: 'post',
    data: {}
  })
}
export function addAPI(data) {
  return http({
    url: `/api/manage/friendLink/add`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/manage/friendLink/edit`,
    method: 'post',
    data
  })
}
export function changeStatusAPI(data) {
  return http({
    url: `/manage/friendLink/changeStatus`,
    method: 'post',
    data
  })
}
export function getDetailAPI(id) {
  return http({
    url: `/api/manage/friendLink/detail`,
    method: 'post',
    params: { id }
  })
}

